<template>
	<div>
		<a href="https://www.bryntum.com/examples/scheduler/#example-dependencies">Bryntum Scheduler</a>
		<!-- <iframe src="https://www.bryntum.com/examples/scheduler/#example-dependencies" width="100%" height="1800px" /> -->
	</div>
</template>

<script>
export default {
	name: 'Production',
}
</script>